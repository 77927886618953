import { Controller } from "stimulus"
import axios from "axios"

export default class extends Controller {
  connect() {
    this.loadInitialData()
    this.element.addEventListener("ajax:success", (event) => this.updateContent(event.detail[0]))
  }

  loadInitialData() {
    // Actually search
    axios.get(this.dataURL)
      .then( response => this.updateContent(response.data) )
      .catch( error => this.handleLoadError(error) )
  }

  updateContent(string_or_node) {
    if(typeof string_or_node == "string") {
      this.element.innerHTML = string_or_node
    } else if(typeof string_or_node == "object") {
      this.element.innerHTML = ""
      this.element.appendChild(
        string_or_node.getElementById("client-invoices") ||
        string_or_node.getElementById("client-collective-invoices")
      )
    }
  }

  handleLoadError(error) { console.log(error) }
  get dataURL() { return this.data.get("url") }
}
